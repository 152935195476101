import React, { useState } from 'react';
import { Link, Element } from 'react-scroll';
import axios from 'axios';
import toast from 'react-hot-toast';
import { FidgetSpinner } from 'react-loader-spinner';

const Home = () => {
  const initialState = {name: '', email: '', address: '', phone: '', subject: '', message: ''};
  const [contactForm, setContactForm] = useState(initialState);
  const [submit, setSubmit] = useState(false);

  const isEmail = (str) => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return str.match(isValidEmail) ? true : false;
  }


  const handleContactForm = (e) => {
    e.preventDefault();
    if (contactForm.name && contactForm.email) {
        if (isEmail(contactForm.email)) {
            // let url = process.env.REACT_APP_API_URL;
            setSubmit(true);
            let url = 'https://uoz0vvmqv2.execute-api.us-east-1.amazonaws.com/v1/contact';
            axios.post(url, contactForm).then((res) => {
              setContactForm(initialState);
              setSubmit(false);
              toast.success('Email sent successfully.', {
                duration: '300',
                position: 'top-right'
              });
            }).catch((err) => {
              setSubmit(false);
              toast.error("Something went wrong. Please try again later.", {
                duration: '300',
                position: 'top-right'
              });
            });
        } else {
          toast.error("Please Enter correct Email", {
            duration: '300',
            position: 'top-right'
          });
        }
    } else {
      toast.error("Please Enter all the details.", {
        duration: '300',
        position: 'top-right'
      });
    }
  };


  return (
    <div className="rounded-xl mb-10 text-center font-noto-sans">
      <div className="container mx-auto mt-5 mb-12">
        <p className="text-4xl text-black-700 font-playfair">
          KAYA AYURVEDA
        </p>
        <p className="text-lg p-4 font-noto-sans-thin pb-8">
          Health and harmony through Ayurveda
        </p>

        <img src="LAVANA-PINDA-SWEDA-MANAL-KIZHI-scaled.jpg" alt="Homepage" className="w-full"/>
      </div>
      
      <Element name='about'>
        <div className="container mx-auto mt-10 flex">
          <div className="bg-[#FFEEFB] lg:w-6/12 lg:my-20 mx-auto z-10 relative lg:right-[-4rem] md:my-0 md:right-0 p-10">
            <h1 className="font-playfair text-4xl">About KAYA</h1>
            <h1 className="font-playfair text-4xl">AYURVEDA</h1>
            <p className="md:w-80 mx-auto my-8 2xl:w-96 sm:w-auto font-noto-sans-thin">
              You’re invited to embark on a journey into the world of traditional medicine, or Ayurveda, the most well-documented ancient holistic health care system for over 5,000 years. Discover your innate potential for wellness and learn the art of maintaining health in all facets of living – mind, body, spirit and senses.
            </p>

            <Link to='contactUs' smooth={true} duration={500}>
              <button className="border border-black bg-white px-5 py-2 font-noto-sans-thin">Contact</button>
            </Link>
          </div>
          <div className="relative lg:left-[-4rem] md:left-0 small-hidden md:flex items-center justify-center">
            <img src="facial.jpg" alt="AboutHomePage" className="w-full"/>
          </div>
        </div>
      </Element>

      <Element name='ourServices'>
        <div className="container mx-auto mt-10 mb-10">
          <div>
            <h1 className="font-playfair text-4xl">Our Services</h1>
            <p className="font-noto-sans-thin p-4">What We Provide</p>
          </div>
          <div className="grid sm:grid-cols-3 gap-5 mt-1">
            <div className="card">
              <div className="p-8 bg-sky-blue font-playfair">Soft Kaya Abhyanga</div>
              <div>
                <img src="service1.png" alt="service1" className="w-full"/>
              </div>
            </div>
            <div className="card">
              <div className="p-8 bg-sky-blue font-playfair">Deep Kaya Abhyanga</div>
              <div>
                <img src="service2.png" alt="service2" className="w-full"/>
              </div>
            </div>
            <div className="card">
              <div className="p-8 bg-sky-blue font-playfair">Deep Marma Kayaabhyanga</div>
              <div>
                <img src="service3.png" alt="service3" className="w-full"/>
              </div>
            </div>
          </div>
          <button className="border border-black bg-white px-5 py-2 mt-8 font-noto-sans-thin">Click To Know More</button>
        </div>
      </Element>


      {/* <div>
        <div>
          <h1>What Our Clients Say</h1>
          <p>Your satisfaction is our priority.</p>
        </div>
        <div className="grid sm:grid-cols-3 gap-5 m-14">
          <div>

          </div>
        </div>
      </div> */}

      <Element name='ourPackages'>
        <div className="bg-sky-blue">
          <div className="container mx-auto pt-10 pb-1">
            <div>
              <h1 className="font-playfair text-4xl">Our Packages</h1>
              <p className="font-noto-sans-thin p-4">Revive. Rejuvenate</p>
            </div>
            <div className="grid sm:grid-cols-3 gap-5 mt-4 mb-10 font-noto-sans-thin">
              <div className="relative bg-white max-h-[250px] overflow-hidden flex items-center justify-center">
                  <img src="nasya.jpg" alt="package1" className="w-full"/>
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <h1 className="text-white text-xl">nasya</h1>
                  </div>
              </div>
              <div className="relative bg-white max-h-[250px] overflow-hidden flex items-center justify-center">
                    <img src="patra-potli.jpg" alt="package2" className="w-full self-center"/>
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <h1 className="text-white text-xl">Patra Potli</h1>
                    </div>
                </div>
                <div className="relative bg-white max-h-[250px] overflow-hidden flex items-center justify-center"> 
                  <img src="Abhyanga.jpg" alt="package3" className="w-full"/>
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <h1 className="text-white text-xl">Abhyanga</h1>
                  </div>
                </div>
                <div className="relative bg-white max-h-[250px] overflow-hidden flex items-center justify-center"> 
                  <img src="Udvartana.jpg" alt="package4" className="w-full"/>
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <h1 className="text-white text-xl">Udvartana</h1>
                  </div>
                </div>
                <div className="relative bg-white max-h-[250px] overflow-hidden flex items-center justify-center"> 
                  <img src="legWash.png" alt="package5" className="w-full"/>
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <h1 className="text-white text-xl">Ayurvedic Leg Wash</h1>
                  </div>
                </div>
                <div className="relative bg-white max-h-[250px] overflow-hidden flex items-center justify-center"> 
                  <img src="herbal-incense-smoke.jpg" alt="package6" className="w-full"/>
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <h1 className="text-white text-xl">Herbal Smoke</h1>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name='contactUs'>
        <div className="bg-custom-grey">
          <div className="container mx-auto pt-10 pb-10">
            <div>
              <h1 className="font-playfair text-4xl">Get in Touch</h1>
            </div>
            <div className="grid sm:grid-cols-2 gap-10 mt-6">
              <div className="h-full font-noto-sans-thin">
                <form className="h-full" onSubmit={handleContactForm}>
                  <div className="mb-3 flex flex-col text-left">
                    <label htmlFor="name" className="text-left text-sm p-1">Name *</label>
                    <input type="text" id="name" value={contactForm.name} onChange={(e) => setContactForm({...contactForm, name: e.target.value})} className="bg-custom-grey border border-black p-1 pl-4 rounded-sm" placeholder="Enter your name" required/>
                  </div>
                  <div className="mb-3 flex flex-col text-left">
                    <label htmlFor="email" className="text-left text-sm p-1">Email *</label>
                    <input type="email" id="email" value={contactForm.email} onChange={(e) => setContactForm({...contactForm, email: e.target.value})} className="bg-custom-grey border border-black p-1 pl-4 rounded-sm" placeholder="Enter your email" required/>
                  </div>
                  <div className="grid sm:grid-cols-2 gap-5">
                    <div className="mb-3 flex flex-col text-left">
                      <label htmlFor="address" className="text-left text-sm p-1">Address</label>
                      <input type="text" id="address" value={contactForm.address} onChange={(e) => setContactForm({...contactForm, address: e.target.value})} className="bg-custom-grey border border-black p-1 pl-4 rounded-sm" placeholder="Enter your address" />
                    </div>
                    <div className="mb-3 flex flex-col text-left">
                      <label htmlFor="phone" className="text-left text-sm p-1">Phone/Mobile</label>
                      <input type="text" id="phone" value={contactForm.phone} onChange={(e) => setContactForm({...contactForm, phone: e.target.value})} className="bg-custom-grey border border-black p-1 pl-4 rounded-sm" placeholder="Enter your number" />
                    </div>
                  </div>
                  <div className="mb-3 flex flex-col text-left">
                    <label htmlFor="subject" className="text-left text-sm p-1">Subject</label>
                    <input type="text" id="subject" value={contactForm.subject} onChange={(e) => setContactForm({...contactForm, subject: e.target.value})} className="bg-custom-grey border border-black p-1 pl-4 w-1/2 rounded-sm" placeholder="Type the subject" />
                  </div>
                  <div className="mb-3 flex flex-col text-left">
                    <label htmlFor="message" className="text-left text-sm p-1">Leave a message</label>
                    <textarea type="text" id="message" value={contactForm.message} onChange={(e) => setContactForm({...contactForm, message: e.target.value})} className="bg-custom-grey border border-black p-1 pl-4 rounded-sm" placeholder="Type your message here..." />
                  </div>
                  { !submit ? (
                    <input type="submit" value="Submit" className="px-5 py-2 mt-4 text-white bg-black"></input>
                  ) : (
                    <div className='flex justify-center'>
                      <FidgetSpinner
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                        ballColors={['#324B33', '#324B33', '#324B33']}
                        backgroundColor="#324B33"
                      />
                  </div>
                  )}
                  
                </form>
              </div>
              <div className='overflow-hidden'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7483.566519329129!2d85.32973619426521!3d27.72252160452439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19adb321ba9d%3A0x53465f77e03fe6e5!2sKaya%20Ayurveda!5e0!3m2!1sen!2sus!4v1686506354965!5m2!1sen!2sus" className='w-full h-full' title='kayaMap' loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                {/* <img src="MapImage.png" alt="mapimage" className="mx-auto"/> */}
              </div>
            </div>
          </div>
        </div>
      </Element>


      {/* <div className="container mx-auto mt-10 mb-10 justify-center">
        <div>
          <h1>Meet our Amazing Team!</h1>
          <p className="p-3">Team work makes the dream work</p>
        </div>
        <div className="grid sm:grid-cols-4 gap-4 mt-2 text-left justify-items-center">
          <div className="max-w-[255px]">
            <img src="Team1.png" alt="team1" className="mx-auto"/>
            <div className="pt-4">
              <h6>NAME</h6>
              <p>Ayurveda</p>
            </div>
          </div>
          <div className="max-w-[255px]">
            <img src="Team2.png" alt="team2" className="mx-auto"/>
            <div className="pt-4">
              <h6>NAME</h6>
              <p>Ayurveda</p>
            </div>
          </div>
          <div className="max-w-[255px]">
            <img src="Team3.png" alt="team3" className="mx-auto"/>
            <div className="pt-4">
              <h6>NAME</h6>
              <p>Ayurveda</p>
            </div>
          </div>
          <div className="max-w-[255px]">
            <img src="Team4.png" alt="team4" className="mx-auto"/>
            <div className="pt-4">
              <h6>NAME</h6>
              <p>Ayurveda</p>
            </div>
          </div>
        </div>
      </div> */}

      <Element name='blogFeed'>
        <div className="container mx-auto">
          <h1 className="pt-6 pb-6 font-playfair text-4xl">Blog Feed</h1>
          <div className="border border-solid mb-4">
            <div className="sm:block md:flex">
              <div className="lg:min-w-[450px] md:min-w-[300px] flex">
                <img src="Blog1.png" alt="blog1" className="mx-auto"/>
              </div>
              <div className="p-8 md:pb-0">
                <h1 className="font-noto-sans text-2xl">Ayurveda Yields Side Benefits, Not Side Effects!</h1>
                <div className="p-8 md:pb-4 font-noto-sans-thin">Lead the way through Ayurveda !Ayurveda, "the science of life," is the alternative medicinal solution gifted from ancient healing science that originated in India more than 5,000 years ago. Ayurveda achieved the honorific title "mother of healing" through a well-organized traditional, curative and preventative healing system extensively practiced in many parts of Asia...</div>
              </div>
            </div>
          </div>
          <div className="border border-solid mb-4">
            <div className="sm:block md:flex">
              <div className="lg:min-w-[450px] md:min-w-[300px] flex">
                <img src="Blog2.png" alt="blog2" className="mx-auto "/>
              </div>
              <div className="p-8 md:pb-0">
                <h1 className="font-noto-sans text-2xl">Ayurveda Yields Side Benefits, Not Side Effects!</h1>
                <div className="p-8 md:pb-4 font-noto-sans-thin">Lead the way through Ayurveda !Ayurveda, "the science of life," is the alternative medicinal solution gifted from ancient healing science that originated in India more than 5,000 years ago. Ayurveda achieved the honorific title "mother of healing" through a well-organized traditional, curative and preventative healing system extensively practiced in many parts of Asia...</div>
              </div>
            </div>
          </div>
          <button className="border border-black bg-white px-5 py-2 mt-4 font-noto-sans-thin">See More</button>
        </div>
      </Element>
    </div>
  );
}

export default Home;
