import React from 'react';
import { Link } from 'react-scroll'

const Header = () => {
    return (
        <div className="sticky top-0 container m-auto z-20 bg-white opacity-80">
            <nav className="flex items-center justify-between flex-wrap p-4">
                <div className="flex items-center flex-shrink-0 text-black mr-6">
                    <a href="/">
                        <span className="font-semibold tracking-tight drop-shadow font-playfair text-2xl">KAYA AYURVEDA</span>
                    </a>
                </div>
            </nav>
            <div className="flex-grow flex lg:items-center p-2 border-t border-black w-10/12 m-auto font-noto-sans-thin">
                    <div className="text-sm flex-grow">
                        <Link to='ourPackages' smooth={true} duration={500}>
                            <a href="/features" className="mt-4 lg:inline-block lg:mt-0 text-black-200 hover:text-black mr-4">
                                Features
                            </a>
                        </Link>
                        <Link to='about' smooth={true} duration={500}>
                            <a href="/about" className="mt-4 lg:inline-block lg:mt-0 text-black-200 hover:text-black mr-4">
                                About
                            </a>
                        </Link>
                        
                        <Link to='ourServices' smooth={true} duration={500}>
                            <a href="/services" className="mt-4 lg:inline-block lg:mt-0 text-black-200 hover:text-black">
                                Services
                            </a>
                        </Link>
                    </div>
            </div>
        </div>
    );
}

export default Header;